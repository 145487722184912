<template>
  <div class="page-container">
    <div class="mine-info">
      <div class="mine-avatar">
        <img v-lazy="avatar" alt="media" />
        <p>{{ name }}</p>
      </div>
    </div>
    <div class="mine-grid">
      <van-grid column-num="3" clickable>
        <van-grid-item
          :icon="AixinImg"
          text="捐赠记录"
          :to="{ name: 'MyDonate' }"
        />
        <van-grid-item
          :icon="ZhengshuImg"
          text="捐赠证书"
          :to="{ name: 'MyCert' }"
        />
        <van-grid-item
          :icon="YiqijuanImg"
          text="捐赠团队"
          :to="{ name: 'MyGroup' }"
        />
      </van-grid>
      <van-grid column-num="3">
        <van-grid-item :icon="FapiaoImg" text="校友返校">
          <navigate-wxmp username="gh_522d91f5ed36">
            <div style="text-align: center">
              <img :src="FapiaoImg" width="28px" height="28px" alt="" />
            </div>
            <div>
              <span style="font-size: 12px; line-height: 1.5; margin-top: 8px"
                >校友返校</span
              >
            </div>
          </navigate-wxmp>
        </van-grid-item>
      </van-grid>
      <van-grid v-if="false" column-num="3">
        <van-grid-item :icon="FapiaoImg" text="我的票据" />
        <van-grid-item :icon="JifenImg" text="我的积分" />
        <van-grid-item :icon="LipinImg" text="积分兑换" />
      </van-grid>
      <van-grid v-if="false" column-num="3">
        <van-grid-item :icon="XiangmuImg" text="项目申请" />
        <van-grid-item :icon="HezuoImg" text="企业合作" />
        <van-grid-item :icon="WuziImg" text="物资捐赠" />
      </van-grid>
    </div>
    <van-cell title="个人信息" is-link :to="{ name: 'MyProfile' }" />
    <van-cell title="我的地址" is-link :to="{ name: 'MyAddress' }" />
    <van-cell title="问题反馈" @click="showContact = true" is-link />
    <van-cell title="关闭页面" @click="closePage" is-link />
    <p class="mine-copyright" style="margin-top: 30rpx">
      山东理工大学教育发展基金会版权所有
    </p>
    <p class="mine-copyright">
      指导老师:张国强 开发维护:KAMINO 当前版本:{{ versionText }}
    </p>
    <p class="mine-copyright">
      <a href="/page/yinsi">隐私政策</a>&nbsp;|&nbsp;<a
        href="http://foundation.sdut.edu.cn"
        >官方网站</a
      >&nbsp;|&nbsp;<a href="https://www.sdut.edu.cn">学校首页</a>
    </p>
    <!--p class="mine-copyright">
      <a href="https://beian.miit.gov.cn/">苏ICP备20043030号</a>
    </p-->
    <tabbar />

    <van-overlay :show="showContact" @click="showContact = false">
      <div class="wrapper" @click.stop>
        <div class="contact-box">
          <div class="contact-qrcode">
            <img :src="QrcodeImg" alt="山东理工大学教育发展基金会微信公众号" />
          </div>
          <div class="contact-text">
            扫描二维码关注山东理工大学教育发展基金会微信服务号，向后台发送消息，我们将及时与您取得联系。<br />点击空白处关闭
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog } from "vant";
import Tabbar from "@/components/Tabbar";
import { getStorage } from "@/utils/storage";
import NavigateWxmp from "@/components/NavigateWxmp";

import AixinImg from "@/assets/images/aixin.png";
import FapiaoImg from "@/assets/images/fapiao.png";
import JifenImg from "@/assets/images/jifen.png";
import LipinImg from "@/assets/images/lipin.png";
import WuziImg from "@/assets/images/wuzi.png";
import XiangmuImg from "@/assets/images/xiangmu.png";
import YiqijuanImg from "@/assets/images/yiqijuan.png";
import HezuoImg from "@/assets/images/hezuo.png";
import ZhengshuImg from "@/assets/images/zhengshu.png";

import QrcodeImg from "@/assets/images/qrcode_contact.png";

export default {
  name: "MinePage",
  components: { Tabbar, NavigateWxmp },
  data() {
    return {
      name: getStorage("name"),
      avatar: getStorage("avatar"),
      item: {},
      AixinImg: AixinImg,
      FapiaoImg: FapiaoImg,
      JifenImg: JifenImg,
      LipinImg: LipinImg,
      WuziImg: WuziImg,
      YiqijuanImg: YiqijuanImg,
      HezuoImg: HezuoImg,
      ZhengshuImg: ZhengshuImg,
      XiangmuImg: XiangmuImg,
      QrcodeImg: QrcodeImg,
      showContact: false,
      versionText: this.$appConfig.versionText,
    };
  },
  mounted() {},
  methods: {
    closePage() {
      Dialog.confirm({
        message: "退出微服务",
      })
        .then(() => {
          wx.closeWindow(); // eslint-disable-line
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.mine-copyright {
  font-size: 0.7rem;
  text-align: center;
  margin: 0;
  line-height: 1.2rem;
}

.mine-info {
  height: 14rem;
  background-color: #1989fa;
}

.mine-avatar {
  text-align: center;
  height: 8.7rem;
  position: relative;
  top: 2.5rem;
}

.mine-avatar img {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 0.1rem solid gray;
}

.mine-avatar p {
  font-size: 1.2rem;
  margin: 0;
}

.contact-box {
  width: 20rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 20%;
}

.contact-qrcode img {
  width: 15rem;
  height: 15rem;
}

.contact-text {
  color: #ffffff;
  font-size: 0.7rem;
  line-height: 1rem;
}

.mine-grid{
  background-color: #ffffff;
}
</style>
